<template id="app">
  <div id="addcontainer">
    <div class="container" style="margin-top: 0px;">
      <div class="top"></div>
      <div class="tab">
        <a
          @click="hasAccount = true"
          :class="{ active: hasAccount }"
          href="javascript:;"
        >
          <i class="iconfont icon-bind" />
          <span>1.编辑基本信息</span>
        </a>
        <a
          @click="Price_inventory"
          :class="{ active: !hasAccount }"
          href="javascript:;"
        >
          <i class="iconfont icon-edit" />
          <span>2.编辑价格库存</span>
        </a>
      </div>
    </div>
    <el-card class="box-card" v-if="hasAccount">
      <div class="text item">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="商品名称" prop="name">
            <el-input v-model="form.name" style="width: 350px;margin-left: 20px;"></el-input>
          </el-form-item>
          <el-form-item label="副标题" prop="title" style="margin-left: 10px;">
            <el-input  v-model="form.title" style="width: 350px;margin-left: 10px;"></el-input>
          </el-form-item>
          <el-form-item label="商品主图"  prop="mainImageUrl">
            <el-upload
              style="margin-left: 20px;"
              name="image"
              id="upload"
              :action="ImageUrl"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-success="handleSuccess"
              :limit="1"
              :headers="headers"
              :file-list="mainFileList"
              :on-remove="onRemove"
              :before-upload="beforeAvatarUpload"
              :on-exceed="onExceed"
            >
              <i style="font-size: 20px;" class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
              :visible.sync="dialogVisible"
              :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="轮播图" prop="imageUrlList" ref="headimgUpload">
            <ImgUpload style="margin-left: 20px;"  v-model="imageUrlList" :limit="9" />
          </el-form-item>
          <el-form-item label="选择店铺" prop="storeId">
            <el-select
              v-model="form.storeId"
              filterable
              placeholder="请选择店铺"
              :filter-method="filterData"
              ref="searchSelect"
              @change="storeChange"
              @focus="storeSelect"
              style="margin-left: 20px;"
            >
              <el-option
                v-for="item in storeList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="商品详情图"
            ref="headimgUpload"
            label-width="90px"
            style="margin-left: 10px;"
          >
            <ImgUpload v-model="goodsList" :limit="9" />
          </el-form-item>
          <el-form-item>
            <el-button @click="$router.push('/group/goods/init/list')">取消</el-button>
            <el-button type="primary" @click="nextStep">下一步</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <!-- 基础价格组件 -->
    <EditDetails v-else @back="backFn" :basicForm="form"></EditDetails>
  </div>
</template>

<script>
import url from "@/utils/file";
import { getStore } from "@/utils/storage";
import { storeList,goodsIdWord } from "@/api/group";
import EditDetails from "./editDetails.vue";
import ImgUpload from "./ImgUpload/index.vue";
export default {
  data() {
    const valiIcon = (rule, value, callback) => {
      // 图片验证
      if (this.imageUrlList.length > 0) {
        callback();
      } else {
        callback(new Error("请上传图片"));
      }
    };

    const valiStore = (rule, value, callback) => {
      // 图片验证
      if (this.form.storeId) {
        callback();
      } else {
        callback(new Error("请选择店铺"));
      }
    };
    const valiImageUrl = (rule, value, callback) => {
      // 图片验证
      if (this.form.mainImageUrl) {
        callback();
      } else {
        callback(new Error("请上传主图"));
      }
    };
    return {
      type: "add", // add添加，edit编辑
      hasAccount: true, //切换标识位
      promoterShow: 'true', //归属启用禁用状态
      form: {
        title: "", //副标题
        storeId: "", //店铺id
        name: "", //商品名称
        mainImageUrl: "", //商品主图
        goodsDetail: [], //详情图
        photoList: [], //轮播图
        isUpdateBanner: false,
        isUpdateDetail: false,
      },
      selfStoreId: "131351", //自营店Id
      dialogImageUrl: "",
      dialogVisible: false,
      ImageUrl: url.url_image, //上传地址
      storeList: [], //店铺列表
      storeQuery: {
        // 店铺列表请求参数
        currPage: 1,
        pageSize: 20,
        name: '',
      },
      goodsList: [], //详情图请求参数处理
      imageUrlList: [], // 轮播图请求参数处理
      mainFileList: [], // 主图回显

      rules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        title: [
          {
            min: 2,
            max: 12,
            message: "长度在 2 到 12 个字符",
            trigger: "blur",
          },
        ],
        storeId: [
          { required: true, message: "请选择店铺", trigger: "blur" },
          { validator: valiStore, trigger: "change" },
        ],
        mainImageUrl: [
          { required: true, message: "请选择商品主图", trigger: "change" },
          { validator: valiImageUrl, trigger: "change" },
        ],
        imageUrlList: [
          { required: true, validator: valiIcon, trigger: "change" },
        ],
      },
    };
  },
  components: {
    EditDetails,
    ImgUpload,
  },
  watch: {

    storeQuery: {
      handler(n, o) {
        this.store();
      },
      deep: true,
    },
    goodsList: {
      handler(n, o) {
        this.form.isUpdateDetail = true;
      },
      deep: true,
    },
    imageUrlList: {
      handler(n, o) {
        this.form.isUpdateBanner = true;
      },
      deep: true,
    },
  },
  async created() {
    // 店铺列表
    if (this.$route.query.isShow == "false") {
      await goodsIdWord({ id: this.$route.query.id }).then((res) => {
        this.form = res.data;
        res.data.photoList.forEach((item) => {
          this.imageUrlList.push(item.imageUrl);
        });
        res.data.goodsDetail.forEach((item) => {
          this.goodsList.push(item.imageUrl);
        });
        this.mainFileList.push({ url: res.data.mainImageUrl });

        this.storeQuery.name=this.form.storeName
        if(this.form.storeId==this.selfStoreId){
        this.promoterShow='false'
      }else{
        this.promoterShow='true'
      }
      });
    }
      this.store()
  },
  methods: {
    storeChange(){
      if(this.form.storeId==this.selfStoreId){
        this.promoterShow='false'
      }else{
        this.promoterShow='true'
      }
    },
    storeSelect(){
      this.storeQuery.name = ""
      this.store()
    },
    //编辑价格库存
  Price_inventory(){
    this.$refs.form.validate((valid) => {
      if (valid) {
        this.form.photoList = [];
        this.imageUrlList.forEach((item, index) => {
          const obj = {
            imageUrl: item, //轮播图
            type: 1, //类型轮播图
            weight: index, //权重
          };
          this.form.photoList.push(obj);
        });
        this.form.goodsDetail = [];
        this.goodsList.forEach((item, index) => {
          const obj = {
            imageUrl: item, //详情图
            type: 2, //类型详情图
            weight: index, //权重
          };
          this.form.goodsDetail.push(obj);
        });
        this.hasAccount = false;
      } else {
        return false;
      }
    });
},
    // 上一步
    backFn() {
      this.hasAccount = true;
      this.mainFileList = []
      this.mainFileList.push({ url: this.form.mainImageUrl });
    },

    // 店铺列表
    store() {
      storeList(this.storeQuery).then((res) => {
        this.storeList = res.data.list;
      });
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //限定上传大小
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 超出限定数量
    onExceed() {
      this.$message.error("商品主图只能上传一张");
    },

    // 图片移除钩子
    onRemove() {
      this.form.mainImageUrl = "";
    },
    // 主图上传成功
    handleSuccess(file, fileList) {
      this.form.mainImageUrl = file.data; // 保存地址给变量
    },

    // 搜索框内容
    filterData(val) {
      this.storeQuery.name = val;
      this.store();
    },
    // 下一步
    nextStep() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.photoList = [];
          this.imageUrlList.forEach((item, index) => {
            const obj = {
              imageUrl: item, //轮播图
              type: 1, //类型轮播图
              weight: index, //权重
            };
            this.form.photoList.push(obj);
          });
          this.form.goodsDetail = [];
          this.goodsList.forEach((item, index) => {
            const obj = {
              imageUrl: item, //详情图
              type: 2, //类型详情图
              weight: index, //权重
            };
            this.form.goodsDetail.push(obj);
          });
          this.hasAccount = false;
        } else {
          return false;
        }
      });
    },
  },
  beforeDestroy() {
    this.goodsList = [];
    this.imageUrlList = [];
    this.form.goodsDetail = [];
    this.form.photoList = [];
  },
  computed: {
    // 上传组件设置请求头
    headers() {
      return {
        Authorization: getStore("token"), // 直接从本地获取token就行
      };
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../../assets/css/common.less";
#app{
  width: 100%;
  height: 100%;
  padding: 0px;
}
#upload /deep/ .el-upload--picture-card {
  width: 102px;
  height: 102px;
  line-height: 102px;
  z-index: 1;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 102px;
  height: 102px;
}
#addcontainer {
  .container {
    height: 120px;
  }
}

.tab {
  background: #fff;
  height: 80px;
  padding-top: 40px;
  font-size: 18px;
  text-align: center;
  a {
    color: #666;
    display: inline-block;
    width: 350px;
    line-height: 40px;
    border-bottom: 2px solid #e4e4e4;
    i {
      font-size: 22px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      margin-left: 4px;
    }
    &.active {
      color: #27ba9b;
      border-color: #27ba9b;
    }
  }
}
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}
.top{
  height: 10px;
  background-color: #f3f3f4;
  width: 100%;
  position: fixed;
  z-index: 999;
}
.box-card {
  width: 100%;
  height: 100%;
}
.el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
    box-shadow: none;
    border: none;
}

.app{
  padding: 20px;
}
/deep/.el-form-item__label {
    text-align: left;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
</style>
