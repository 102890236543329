<template>
  <div id="addcontainer">
    <el-card class="box-card">
      <div class="text item">
        <el-form ref="form"
                 :model="form"
                 label-width="80px"
                 :rules="rules">
          <el-form-item label="成本价"
                        prop="costPrice">
            <el-input v-model="form.costPrice"
                      style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label-width="80px"
                        label="团购价"
                        prop="price">
            <el-input style="width: 300px;margin-left: -10px;"
                      v-model="form.price">
            </el-input>
          </el-form-item>
          <el-form-item label="划线价"
                        prop="listPrice">
            <el-input v-model="form.listPrice"
                      style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="商品库存"
                        prop="stock">
            <el-input v-model="form.stock"
                      style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="商品重量"
                        prop="quality">
            <el-input v-model="form.quality"
                      style="width: 80px"></el-input>
            <div id="select">
              <el-select v-model="form.basicUnit"
                         style="width: 50px">
                <el-option :label="item"
                           :value="item"
                           v-for="item in unitList.basicUnit"
                           :key="item"></el-option>
              </el-select>
            </div>
            <div id="select">
              <el-select v-model="form.meterUnit"
                         style="width: 70px">
                <el-option :label="item"
                           :value="item"
                           v-for="item in unitList.meterUnit"
                           :key="item"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item style="margin-left: 10px;"
                        label="附加单位">
            <el-input v-model="form.extraNum"
                      style="width: 80px;margin-left: -10px;"></el-input>
            <div id="select">
              <el-select v-model="form.extraUnit"
                         placeholder="无"
                         style="width: 50px">
                <el-option :label="item"
                           :value="item"
                           v-for="item in unitList.extraUnit"
                           :key="item"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="商品单位"
                        prop="basicUnit">
            <el-select v-model="form.bulkUnit"
                       placeholder="请选择商品单位">
              <el-option :label="item"
                         :value="item"
                         v-for="item in unitList.bulkUnit"
                         :key="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="已售件数"
                        prop="saleFakeCount"
                        style="margin-left: 10px;">
            <el-input v-model="form.saleFakeCount"
                      style="width: 300px;margin-left: -10px;"></el-input>
            <p class="prompt">已售件数不计入后台数据统计</p>
          </el-form-item>
          <el-form-item label="商品状态"
                        prop="enabled">
            <el-radio-group v-model="form.enabled">
              <el-radio v-model="form.enabled"
                        :label="true">{{
                "上架"
              }}</el-radio>
              <el-radio v-model="form.enabled"
                        :label="false">{{
                "暂不上架"
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="截止时间"
                        prop="expirationTime">
            <el-date-picker v-model="form.expirationTime"
                            type="datetime"
                            placeholder="选择日期时间"
                            value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button @click="$router.push('/group/goods/init/list')">取消</el-button>
            <el-button type="primary"
                       @click="back">上一步</el-button>
            <el-button type="primary"
                       @click="onSubmit">立即提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  goodsnUit
} from "@/api/goods";
import {
  goodsAdd,
  goodsEdit
} from "@/api/group";
import BigNumber from "bignumber.js";
import debounce from "../../../utils/debounce"
export default {
  props: {
    basicForm: {
      type: Object,
      required: true,
    },
  },
  data () {
    // 自定义校验规则
    const listPrice = (rule, value, callback) => {
      const r = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/ // 不能为负数
      if (value == null || String(value).trim() === "") {
        callback(new Error("不能为空"));
      } else if (value <= 0) {
        callback(new Error("必须大于0"));
      } else if (!r.test(value)) {
        callback(new Error("请输入数字"));
      } else if (Number(value) <= this.form.price) {
        callback(new Error("划线价不能小与或等于团购价"));
      } else {
        return callback();
      }
    };
    const price = (rule, value, callback) => {
      const r = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/ // 不能为负数
      if (value == null || String(value).trim() === "") {
        return callback(new Error("不能为空"));
      } else if (value <= 0) {
        return callback(new Error("必须大于0"));
      } else if (!r.test(value)) {
        return callback(new Error("请输入数字"));
      } else if (Number(value) <= this.form.costPrice) {
        console.log(value)
        return callback(new Error("团购价不能小与或等于成本价"));
      } else {
        return callback();
      }
    };

    const costPrice = (rule, value, callback) => {
      const r = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/ // 不能为负数
      if (value == null || String(value).trim() === "") {
        return callback(new Error("不能为空"));
      } else if (value <= 0) {
        return callback(new Error("必须大于0"));
      } else if (!r.test(value)) {
        return callback(new Error("请输入数字"));
      } else {
        return callback();
      }
    };

    // 自定义校验规则
    const stockMoney = (rule, value, callback) => {
      const r = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/ // 不能为负数
      if (value == null || String(value).trim() === "") {
        return callback(new Error("不能为空"));
      } else if (value <= 0) {
        return callback(new Error("必须大于0"));
      } else if (!r.test(value)) {
        return callback(new Error("请输入数字"));
      } else {
        return callback();
      }
    };

    const inventory = (rule, value, callback) => {
      const r = /([0-9]\d*\.?\d*)|(0\.\d*[0-9])/; // 数字
      const e = /^[+]{0,1}(\d+)$/ //正整数
      if (value == null || String(value).trim() === "") {
        return callback(new Error("不能为空"));
      } else if (value < 0) {
        return callback(new Error("必须大于0"));
      } else if (!r.test(value)) {
        return callback(new Error("请输入数字"));
      } else if (!e.test(value)) {
        return callback(new Error("不能输入小数"));
      } else {
        return callback();
      }
    };
    return {
      form: {
        bulkUnit: "箱",
        costPrice: "", //成本价
        listPrice: "", //划线价
        price: "", //团购价
        stock: "", //库存
        quality: "", //商品重量
        extraUnit: "", //附加单位
        extraNum: "", //附加单位数量
        basicUnit: "kg", //商品单位
        saleFakeCount: "", //已售件数
        enabled: false, //商品状态
        meterUnit: "毛重", //计量单位
        //商品详情参数
        expirationTime: ''
      },
      rules: {
        expirationTime:[{
          required: true,
            message: "请选择截止时间",
            trigger: "blur"
        }],
        costPrice: [
          {
            required: true,
            message: "请输入成本价",
            trigger: "blur"
          },
          {
            validator: costPrice,
            trigger: "blur"
          },
        ],
        price: [{
          required: true,
          message: "请输入团购价",
          trigger: "blur"
        },
        {
          validator: price,
          trigger: "blur"
        },
        ],
        listPrice: [{
          required: true,
          message: "请输入划线价",
          trigger: "blur"
        },
        {
          validator: listPrice,
          trigger: "blur"
        },
        ],
        stock: [{
          required: true,
          message: "请输入库存",
          trigger: "blur"
        },
        {
          validator: inventory,
          trigger: "blur"
        },
        ],
        quality: [{
          required: true,
          message: "请输入商品重量",
          trigger: "blur"
        },
        {
          validator: stockMoney,
          trigger: "blur"
        },
        ],
        basicUnit: [{
          required: true,
          message: "请选择商品单位",
          trigger: "blur"
        },
        ],
        enabled: [{
          required: true,
          message: "请选择商品状态",
          trigger: "change"
        },],
      },
      unitList: {}, //单位列表
    };
  },

  created () {
    goodsnUit().then((res) => {
      this.unitList = res.data;
      this.unitList.extraUnit.push("无")
    });
    if (this.$route.query.isShow === "false") {
      this.form = this.basicForm;
      this.form.costPrice = this.basicForm.costPrice / 100;
      this.form.price = this.basicForm.price / 100;
      this.form.listPrice = this.basicForm.listPrice / 100
    }
  },

  //离开该组件价格乘100
  beforeDestroy () {
    this.form.costPrice = this.basicForm.costPrice * 100;
    this.form.price = this.basicForm.price * 100;
    this.form.listPrice = this.basicForm.listPrice * 100
  },
  methods: {
    // 立即提交
    onSubmit: debounce(function () {
      if (this.form.extraUnit == "无") {
        this.form.extraUnit = ""
      }
      this.$refs.form.validate((valid) => {
        console.log('1', this.form)
        console.log('2', this.basicForm)
        if (valid) {
          const obj = {
            ...this.form,
            ...this.basicForm,
            storeName: '123',
            costPrice: BigNumber(this.form.costPrice).times(100).toString(),
            price: BigNumber(this.form.price).times(100).toString(),
            listPrice: BigNumber(this.form.listPrice).times(100).toString(),
          };
          // 编辑
          if (this.$route.query.isShow === "false") {
            goodsEdit(obj).then((res) => {
              if (res.status == 10000 && res.data == true) {
                this.$router.push("/group/goods/init/list");
              }
            });
          } else if (this.$route.query.isShow === "true") {
            // 添加
            goodsAdd(obj).then((res) => {
              if (res.status == 10000 && res.data == true) {
                this.$router.push("/group/goods/init/list");
              }
            });
          }
        } else {
          return
        }
      });
    }, 500),
    // 上一步
    back () {
      this.$emit("back");
    },
  },
};
</script>

<style lang='less' scoped>
#addcontainer {
  .container {
    height: 120px;
  }
}

#select {
  display: inline-block;
  margin-left: 10px;
}

/deep/ .el-input--suffix .el-input__inner {
  padding: 0;
  text-align: center;
}

/deep/ .el-input__suffix {
  width: 17px;
}

.tab {
  background: #fff;
  height: 80px;
  padding-top: 40px;
  font-size: 18px;
  text-align: center;

  a {
    color: #666;
    display: inline-block;
    width: 350px;
    line-height: 40px;
    border-bottom: 2px solid #e4e4e4;

    i {
      font-size: 22px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
      margin-left: 4px;
    }

    &.active {
      color: #27ba9b;
      border-color: #27ba9b;
    }
  }
}

.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 100%;
  height: 100%;
}
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
  border: none;
}

.iconcolour {
  color: green;
  font-size: 20px;
}

.prompt {
  font-size: 12px;
  color: #aaaaaa;
}
</style>
